import Button from "../components/global/Button"
import Layout from "../components/global/Layout"
import MarketoForm from "../components/global/MarketoForm"
import React from "react"
import SectionWrapper from "../components/sections/SectionWrapper"
import { queries } from "../breakpoints"
import styled from 'styled-components';

const RequestACall = ({ location }) => {

  const showThankYouScreen = () => {
    // Show the thank you message  
    let thankYou = document.getElementById('form-thank-you')
    thankYou.style.display = 'block';

    // Hide the form
    let form = document.getElementById('contact-form')
    form.style.display = "none"
  }

  return (
    <Layout 
      location={location} 
      title={"Request a call | Get in Touch | Grubhub Corporate Accounts"} 
      description={"Want to get in touch with Grubhub for information about setting up a Corporate Account? Contact us today, it's easy to get started."}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "request a call",
        pageName: "request a call",
        brand: "grubhub"
      }}
      >
        <StyledSectionWrapper id="contact-form" sectionPadding="50px 0">
            <StyledFormContainer className="row">
                <FormWrapper className="col-lg-6 col-12">
                    <h2>Learn more about Grubhub Corporate’s Enterprise Accounts</h2>
                    <FormStyles>
                        <MarketoForm labelBellow={false} id={1742} onSubmit={() => showThankYouScreen()}/>
                    </FormStyles>
                    <SignIn>Already have an account? <a href="https://www.grubhub.com/login?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_corporate-client-help-site&utm_content=nopromo_request-a-call-form">Sign in</a></SignIn>
                    <Legal>By clicking "Submit" or by using this site, you agreee to Grubhub's <a href="https://www.grubhub.com/legal/terms-of-use">terms of use</a> and <a href="https://www.grubhub.com/legal/privacy-policy">privacy policy</a>.</Legal>
                </FormWrapper>
                <div className="col-lg-6 col-12" style={{padding:0}}>
                    <FormImage />
                </div>
            </StyledFormContainer>
        </StyledSectionWrapper>

        {/* Thank you page (hidden until successful submit) */}
        <SectionWrapper id="form-thank-you" style={{ display: 'none' }} sectionPadding="100px 0">
          <ThankYouContainer className="row">
            <ThankYouMessageContainer className="col-lg-6 col-12">
              <img 
                width={125}
                alt=""
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673958/Grubhub%20Corporate%20Website/2021/Navigation/SuccessState-Checkmark.svg"/>
              <h2>Thanks for reaching out, we'll be in touch shortly!</h2>
              <p>In the meantime, check out our blog to learn more about supporting your hybrid workforce.</p>
              <Button to="/blog/" type="primary">See blog</Button>
            </ThankYouMessageContainer>
            <div className="col-lg-6 col-12" style={{padding:0}}>
              <FormImage />
            </div>
          </ThankYouContainer>
        </SectionWrapper>
    </Layout>
  )
}

export default RequestACall

const StyledSectionWrapper = styled(SectionWrapper)`
  @media(${queries.max_break_md}){
    padding:0;
    padding-bottom: 50px;
    .container{
      max-width: unset;
    }
  }
`;
const FormImage = styled.div`
    background-image: url('https://res.cloudinary.com/grubhub-marketing/image/upload/v1628100788/Grubhub%20Corporate%20Website/2021/Photography%20Elements/5-ContactUs/RequestACall-Image-2.png');
    width: 100%;
    height: 100%;
    background-size:cover;
    border-radius:0 4px 4px 0;
    background-position: center center;
    @media(${queries.max_break_lg}){
        background-position: bottom center;
        height:250px;
    }
`;

const StyledFormContainer = styled.div`
    background-color: #f6f6f6;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    font-family: var(--font-body);
    box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    min-height: 50vh;
    border-radius:4px;
    @media(${queries.max_break_lg}){
        flex-wrap: wrap-reverse;
    }
`;
const FormWrapper = styled.div`
  display:flex;
  flex-flow:column;
  justify-content:center;
  align-items: flex-start;
  text-align:left;
  padding:15px;
    @media(${queries.min_break_lg}){
        padding: 50px;
    }
    h2{
        font-weight:bold;
        font-size: var(--fontSize-5);
        margin:20px 0;
    }
`;
const SignIn = styled.p`
    color:#606060;
    font-size: var(--fontSize-0);
    margin-top:20px;
`;
const Legal = styled.small`
    color:#A5A4AA;
    font-size: var(--fontSize-legal);
    font-weight: var(--fontWeight-light);
    width:215px;
`;
const ThankYouContainer = styled.div`
    background-color: #f6f6f6;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    font-family: var(--font-body);
    box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    min-height: 50vh;
    border-radius:4px;
    @media(${queries.max_break_lg}){
        flex-wrap: wrap-reverse;
    }
`;
const ThankYouMessageContainer = styled.div`
  display:flex;
  flex-flow:column;
  justify-content:center;
  align-items: center;
  text-align:center;
  padding: 50px;
  h2{
    font-weight:bold;
    font-size: var(--fontSize-5);
    margin:20px 0;
  }
  p{
    font-size: var(--fontSize-2)
  }
`;

const FormStyles = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    font-family: var(--font-body);
    @media(${queries.max_break_lg}){
        width:100%;
    }

    .mktoFormCol {
        margin-bottom: 25px;
        @media(${queries.min_break_md}){
            margin-bottom: 35px;
            width: 50% !important;

            &:first-child {
                margin-right: 10px;
            }

            &:nth-child(2) {
                margin-left: 10px;
            }
        }
    }

    .mktoLabel {
        display: flex;
        font-size: var(--fontSize-0);
        font-weight: 300;
        margin-bottom: 3px;
        color: var(--color-secondary);
    }

    .mktoFormRow {
        display: flex;
        width: 100% !important;
        flex-flow: column;
        @media(${queries.min_break_md}){
            flex-flow: row;
        }
    }

    input {
        height: 40px;
        border: 1px solid #AEAEAE;
        border-radius: 2px;
        width: 100% !important;
        font-family: var(--fontFamily-body);
        padding: 0 10px;
    }

    .mktoButton {
        display: inline;
        padding: 12px 30px;
        border-radius: 100px;
        font-weight: bold;
        font-size: var(--fontSize-1);
        min-width: 175px;
        display: inline-block;
        text-align: center;
        background-color: var(--color-primary);
        color: white;
        font-family: var(--font-body);
        border: none;
        transition: all .1s;

        &:hover {
            color: white;
            text-decoration: none;
            background-color: var(--color-primary-dark);
        }
        &:active{
            background-color: var(--color-primary-darker);
        }
    }

    // Validation styles
    .mktoInvalid{
        border-color:#C3363E;
    }
    .mktoErrorMsg{
        font-family: var(--fontFamily-body);
        color:#C3363E;
        font-size: 12px;
    }

    .mktoValid{
        border-color: #13AA37;
    }
    @media(${queries.max_break_md}){
        #Phone, #PostalCode{
            max-width:200px;
        }
    }

    .mktoRadioList {
        max-width: 200px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .mktoRadioList input[type="radio"] {
        top: inherit !important;
        left: inherit !important;
        width: inherit !important;
        position: inherit !important;
        float: right;
        clear: right;
        width: 20px !important;
        height: 20px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .mktoRadioList label {
        display: block !important;
        float: left;
        clear: left;
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 20px!important;
        padding-left: 4px!important;
        font-size: var(--fontSize-0);
        font-weight: 300;
    }
`;
